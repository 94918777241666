import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/mh-al-website/mh-al-website/src/components/Layout/Layout.js";
import SEO from "layout/SEO";
import HomeBanner from "components/StaticPages/Home/HomeBanner"; // import HomeSavings from "components/StaticPages/Home/HomeSavings"

import HomeHowProgramWorks from "components/StaticPages/Home/HomeHowProgramWorks"; // import HomeGetStartedBanner from "components/StaticPages/Home/HomeGetStartedBanner"

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Home" mdxType="SEO" />
    <HomeBanner mdxType="HomeBanner" />
    {
      /* <HomeSavings /> */
    }
    <HomeHowProgramWorks mdxType="HomeHowProgramWorks" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      